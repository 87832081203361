<template>
  <div class="mb-3">
    <div class="border border-rounded border-primary rounded-3 bg-white px-3">
      <h3 class="text-center pt-3 mb-3">Sign Up To Create More Epic Road Trips</h3>
      <p class="text-center mb-3"><a href="/#" @click.prevent="signUp">Sign up for a free account</a> to save this epic road trip, and create more.</p>
      <button class="d-block mx-auto btn btn-primary mb-3"
        @click.prevent="signUp">
        Sign Up For a Free Account
      </button>
      <aside class="text-center mb-3">If you already have an existing Epic Road Trip Planner account, <a href="/#" @click.prevent="signIn">signing in here</a> will add this epic road trip to your account.</aside>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthModals } from "../composables/authModals";

const { showSignInModal, showSignUpModal } = useAuthModals();

function signIn() {
  showSignInModal.value = true;
}

function signUp() {
  showSignUpModal.value = true;
}
</script>